import * as React from 'react';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ProductSummaryShape } from 'lib/util/productSummaryShape';

import { ProductSummaryCardView } from './ProductSummaryCardView';
import { useQueryString } from 'hooks/useQueryString';

interface OwnProps {
  product: ProductSummaryShape;
  orientation?: 'VERTICAL' | 'HORIZONTAL';
}
type Props = OwnProps;

export const ProductSummaryCard = ({ orientation, product }: Props) => {
  const { apiKey, useApiKeyInPaths } = React.useContext(ApiKeyContext);

  const configQueryString = useQueryString();

  let productPath = useApiKeyInPaths
    ? `/${apiKey}/products/${product.id}`
    : `/products/${product.id}`;
  if (configQueryString) {
    productPath = `${productPath}?${configQueryString}`;
  }

  if (product.isPartnershipHubSupplierProduct && product.partnershipHubSupplierProductPageUrl) {
    productPath = `https://${product.partnershipHubSupplierProductPageUrl}`;
  }

  return (
    <ProductSummaryCardView product={product} orientation={orientation} productUrl={productPath} />
  );
};
